import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import useSWR from 'swr';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCodeReader from 'react-qr-code';

import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import useLang from '../lib/hook/useLang';

const CoinTypeBtn = tw.button`
  flex justify-center items-center
  flex-1 h-[40px]
  bg-white/10
 text-white text-sm
  border-opacity-80
  rounded-md
  border-2 ${({ $isSelected }) => ($isSelected ? 'border-white/80' : 'border-transparent')}
`;

const CoinPriceBtn = tw.button`
  flex justify-center items-center
  h-[32px]
  text-sm
  bg-white/10
  text-white text-[12px]
  rounded-md
  w-full
  active:ring-white active:ring-2
`;

const AccountWriteBtn = tw.button`
  flex justify-center items-center
  h-[40px]
  bg-[#860099]
  text-white
  cursor-pointer
`;

const InputContainer = tw.div`
  flex flex-col space-y-3
`;

const Label = tw.label`
text-white font-sm`;

const Input = tw.input`
  h-10 w-full rounded-md
  border border-white/20
  bg-white/10
  text-white
  pl-2
  placeholder:text-right
  placeholder:pr-2
  focus:outline-none focus:border-[#860099] focus:ring-[#860099] focus:ring-1 sm:text-sm"
`;

const InfoTitle = tw.p`
  text-[12px] font-semibold text-white/80
`;

const InfoText = tw.p`
  text-[10px] T:text-[12px] font-light text-white/60
`;

const Line = tw.span`
  border-[0.5px] border-white/10 block
`;

export default function PointCharge() {
  const { lngT } = useLang('mypage:account');
  const navigate = useNavigate();
  const [paymentType, setPaymentType] = useState('pix'); //pix or coin
  const [chargePoint, setChargePoint] = useState(0);
  const [AmountTobeDeposited, setAmountTobeDepoisted] = useState(0);
  const [showQR, setShowQR] = useState(false);
  const [QRCode, setQRCode] = useState();
  const [isLoading, setLoading] = useState(false);
  const { data } = useSWR(`${process.env.REACT_APP_API_URL}/exchange`);
  const [txid, setTxid] = useState();
  const { data: chargeStatus } = useSWR(txid ? `${process.env.REACT_APP_API_URL}/ib/charge/status/${txid}` : null, {
    refreshInterval: 1000,
  });
  const [isCopied, setCopied] = useState(false);
  const [chargeSuccess, setChargeSuccess] = useState(false);

  const handleChangeChargeAmount = e => {
    const value = Number(e.target.value);

    if (isNaN(value)) return;
    setChargePoint(Number(e.target.value));
  };

  const handleClickAddChargePoint = addedAmount => () => {
    setChargePoint(chargePoint + addedAmount);
  };

  const handleClickCharge = async () => {
    if (chargePoint < 100) {
      return alert('The minimum amount to top up is 100 points.');
    }
    setLoading(true);
    setShowQR(true);

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/ib/charge`,
        {
          chargePoint,
          transaction_amount: AmountTobeDeposited,
        },
        {
          withCredentials: true,
        },
      );
      setQRCode(data.paymentQRCode);
      setTxid(data.txid);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chargePoint === 0 || !data) return;
    const { exchangeRate } = data;
    setAmountTobeDepoisted(Number(((Math.ceil(exchangeRate * 100) / 100) * chargePoint) / 100));
  }, [chargePoint, data]);

  useEffect(() => {
    if (!chargeStatus) return;

    console.log('@chargeStatus: ', chargeStatus);

    if (chargeStatus.status === 'approved') {
      setChargeSuccess(true);
    }
  }, [chargeStatus, navigate]);

  return (
    <div className="space-y-8">
      {(showQR || !data) && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center bg-black/50 z-[99]">
          {!data ? (
            <div className="text-white text-xl">{lngT('gettingExchageRate')}</div>
          ) : isLoading ? (
            <div className="text-white text-xl">{lngT('gettingQrCode')}</div>
          ) : chargeSuccess ? (
            <div className="flex flex-col items-center space-y-10 px-16 py-10 bg-black/90 rounded-md">
              <div className="flex flex-col items-center space-y-2 text-white font-bold">
                <span className="text-2xl">{chargePoint} points</span>
                <span className="text-2xl">{lngT('pointCharged')}</span>
              </div>
              <div
                className="cursor-pointer text-white px-5 py-3 rounded-md bg-[#8E00CB] hover:opacity-50"
                onClick={() => navigate('/mypage/userinfo')}
              >
                {lngT('confirm')}
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center space-y-5">
              <CloseIcon
                className="text-white text-[20px] cursor-pointer border-2 border-white rounded-full"
                onClick={() => {
                  setShowQR(false);
                  setChargePoint(0);
                  setAmountTobeDepoisted(0);
                  setCopied(false);
                }}
              />
              <div className="text-white text-xl T:w-full w-[230px] text-center">{lngT('pleaseWait')}</div>
              {/* <img className="w-[200px] h-[200px]" src={QRUrl} alt="" /> */}
              <QRCodeReader size={200} style={{ height: 'auto' }} value={QRCode} viewBox={`0 0 200 200`} />
              <div className="flex T:flex-row flex-col bg-white rounded-md">
                <input className="T:rounded-l-md rounded-t-md px-3 disabled:bg-white" disabled value={QRCode} />
                <CopyToClipboard
                  className={`w-full T:w-[150px] T:rounded-r-md py-2 text-white ${
                    isCopied ? 'bg-[#860099]/40' : 'bg-[#860099]'
                  }`}
                  text={QRCode}
                  onCopy={() => setCopied(true)}
                >
                  <button className="hover:opacity-70">{isCopied ? 'Copied!!' : 'Copy QR code'}</button>
                </CopyToClipboard>
              </div>
            </div>
          )}
        </div>
      )}
      <InputContainer className="flex flex-col">
        <Label>{lngT('charge:title:1')}</Label>
        <div className="flex flex-row space-x-6">
          <CoinTypeBtn $isSelected={paymentType === 'pix'} onClick={() => setPaymentType('pix')}>
            PIX Pay
          </CoinTypeBtn>
          <CoinTypeBtn
            $isSelected={paymentType === 'coin'}
            disabled
            className="opacity-40"
            onClick={() => setPaymentType('coin')}
          >
            COIN
          </CoinTypeBtn>
        </div>
      </InputContainer>
      <div className="flex flex-col space-y-5">
        <Line />
        <InputContainer className="flex flex-col">
          <Label>{lngT('charge:title:2')}</Label>
          <div className="relative">
            <Input type="text" pattern="[0-9]+" value={chargePoint} onChange={handleChangeChargeAmount} disabled />
            <div className="absolute top-1/2 transform -translate-y-1/2 right-5 z-50 text-white text-[12px]">
              {/*POINTs*/ lngT('points')} (100 P = 1 USD)
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <CoinPriceBtn onClick={handleClickAddChargePoint(100)}>100</CoinPriceBtn>
            <CoinPriceBtn onClick={handleClickAddChargePoint(500)}>500</CoinPriceBtn>
            <CoinPriceBtn onClick={handleClickAddChargePoint(1000)}>1,000</CoinPriceBtn>
            <CoinPriceBtn onClick={handleClickAddChargePoint(10000)}>10,000</CoinPriceBtn>
            <CoinPriceBtn
              onClick={() => {
                setChargePoint(0);
                setAmountTobeDepoisted(0);
              }}
            >
              {lngT('charge:title:4')}
            </CoinPriceBtn>
          </div>
          <div className="flex flex-row space-x-2">
            <InfoTitle>{lngT('charge:guideText:3')}</InfoTitle>
            <InfoText>{lngT('charge:guideText:1')}</InfoText>
          </div>
        </InputContainer>
        <Line />
        <InputContainer className="flex flex-col">
          <Label>{lngT('charge:title:3')}</Label>
          <div className="relative">
            <Input type="text" disabled value={AmountTobeDeposited} />
            <div className="absolute top-1/2 transform -translate-y-1/2 right-5 z-50 text-white text-[12px]">BRL</div>
          </div>
        </InputContainer>
        <div className="flex flex-row space-x-2">
          <InfoTitle>{lngT('charge:guideText:3')}</InfoTitle>
          <InfoText>{lngT('charge:guideText:2')}</InfoText>
        </div>
      </div>
      <div className="hidden T:flex flex-row T:justify-center items-center text-[24px] font-bold text-white px-[6px] T:pb-[24px]">
        <AccountWriteBtn className=" T:w-[170px] text-[14px] px-[20px] rounded-xl" onClick={handleClickCharge}>
          {lngT('charge')}
        </AccountWriteBtn>
      </div>
      <AccountWriteBtn className="fixed left-0 right-0 bottom-0 T:hidden" onClick={handleClickCharge}>
        {lngT('charge')}
      </AccountWriteBtn>
    </div>
  );
}
