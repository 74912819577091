import { useEffect, useState } from 'react';
import useSWR from 'swr';
import tw from 'tailwind-styled-components';
import { toast } from 'react-toastify';
import axios from 'axios';

import useUser from '../lib/hook/useUser';
import useLang from '../lib/hook/useLang';

import RegisterWithdrawalInfo from './RegisterWithdrawalInfo';
import Pincode from './Pincode';
import SpinnerLoader from './SpinnerLoader';

const CoinTypeBtn = tw.button`
  flex justify-center items-center
  flex-1 h-[40px]
  bg-white/10
  text-white text-sm
  border-opacity-80
  rounded-md
  border-2 ${({ $isSelected }) => ($isSelected ? 'border-white/80' : 'border-transparent')}
`;

const CoinPriceBtn = tw.button`
  flex justify-center items-center
  h-[36px]
  text-sm
  bg-[#860099]/40 hover:bg-[#860099]/60
  border border-transparent hover:border-[2px]
  text-white text-[12px]
  rounded-md
  w-full
  active:ring-[#860099] active:ring-2
`;

const AccountWriteBtn = tw.button`
  flex justify-center items-center
  h-[40px]
  bg-[#860099]
  text-white
  cursor-pointer
`;

const InputContainer = tw.div`
  flex flex-col space-y-3
`;

const Label = tw.label`
text-white font-regular
`;

const Input = tw.input`
  h-10 w-full rounded-md
  border border-white/20
  bg-white/10
  text-white
  pl-7
  placeholder:text-right
  placeholder:pr-2
  focus:outline-none focus:border-[#860099] focus:ring-[#860099] focus:ring-1 sm:text-sm"
`;

const InfoTitle = tw.p`
  text-[12px] font-semibold text-white/80
`;

const InfoText = tw.p`
  text-[10px] T:text-[12px] font-light text-white/60
`;

const Line = tw.span`
  border-[0.5px] border-white/10 block
`;

export function floorSecondDecimal(number) {
  return Math.floor(number * 100) / 100;
}

export default function Withdrawal() {
  const [user] = useUser();
  const { lngT } = useLang('mypage:account');

  const [paymentType, setPaymentType] = useState('pix');
  const [withdrawalPoint, setWithdrawalPoint] = useState(0);
  const [requestAmount, setRequestAmount] = useState(0);
  const [feeAmount, setFeeAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [isPincodeOpen, setPincodeOpen] = useState(false);
  const [isPincodeValid, setPincodeValid] = useState(false);
  const [withdrawalLoading, setWithdrawalLoading] = useState(false);
  const [successWithdrawalRequest, setSuccessWithdrawalRequest] = useState(false);

  const { data: balance } = useSWR(`${process.env.REACT_APP_API_URL}/balance`);
  const { data: exchangeRateData } = useSWR(`${process.env.REACT_APP_API_URL}/exchange`);
  const { data: withdrawalFee } = useSWR(`${process.env.REACT_APP_API_URL}/withdrawal/fee`);

  const handleChangeWithdrawalAmount = e => {
    const value = e.target.value.replace(/[^\d.]/g, '');
    if (isNaN(value)) return;
    setWithdrawalPoint(value);
  };

  const handleClickAddChargePoint = addedAmount => () => {
    if (!balance || !exchangeRateData) return;
    const { coin } = balance.data;

    setWithdrawalPoint(withdrawalPoint + addedAmount >= coin ? coin : withdrawalPoint + addedAmount);
  };

  const handleClickWithdrawal = async () => {
    if (!balance) return;
    const { point } = balance.data;

    if (withdrawalPoint < 1000) return alert(`Over 1000 points that can be withdrawn`);
    if (withdrawalPoint > point) return alert(`Under ${point} points that can be withdrawn`);

    const { exchangeRate: exchangeRateDatas } = exchangeRateData;
    const exchangeRate = Math.ceil(exchangeRateDatas * 100) / 100;

    if (!paymentType || !withdrawalPoint || !requestAmount || !feeAmount || !totalAmount || !exchangeRate) return;

    setPincodeOpen(true);
  };

  // 출금 요청하기
  useEffect(() => {
    if (!isPincodeValid) return;

    const { exchangeRate: exchangeRateDatas } = exchangeRateData;
    const exchangeRate = Math.ceil(exchangeRateDatas * 100) / 100;

    if (!paymentType || !withdrawalPoint || !requestAmount || !feeAmount || !totalAmount || !exchangeRate) return;

    (async function () {
      setWithdrawalLoading(true);

      const body = {
        paymentType,
        withdrawalPoint: String(withdrawalPoint),
        requestAmount: String(requestAmount),
        feeAmount: String(feeAmount),
        totalAmount: String(totalAmount),
        exchangeRate: String(exchangeRate),
      };

      try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/withdrawal`, body, {
          withCredentials: true,
        });

        if (data.ok) {
          setWithdrawalPoint(0);
          setRequestAmount(0);
          setFeeAmount(0);
          setTotalAmount(0);
          setSuccessWithdrawalRequest(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setPincodeValid(false);
      }
    })();
  }, [isPincodeValid]);

  // request amount구하기
  useEffect(() => {
    if (withdrawalPoint === 0 || !exchangeRateData) return;
    const { exchangeRate } = exchangeRateData;

    setRequestAmount(Number((((Math.ceil(exchangeRate * 100) / 100) * withdrawalPoint) / 100).toFixed(2)));
  }, [withdrawalPoint, exchangeRateData]);

  // total amount구하기
  useEffect(() => {
    if (!requestAmount || !withdrawalFee?.fee) return;

    // 2자리 올림 안한 수수료
    // console.log(AmountTobeWithdrawal * Number(withdrawalFee.fee));

    const feeAmount = Math.ceil(requestAmount * Number(withdrawalFee.fee) * 100) / 100;
    setFeeAmount(feeAmount);

    const totalAmount = Math.ceil((requestAmount - feeAmount) * 100) / 100;
    setTotalAmount(totalAmount);
  }, [requestAmount]);

  if (!user || !balance) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <SpinnerLoader className="w-[30px] h-[30px]" />
      </div>
    );
  }

  if (!user.withdrawalInfo_id) {
    return (
      <div className="space-y-8">
        <RegisterWithdrawalInfo />
      </div>
    );
  }

  return (
    <div className="space-y-8">
      {isPincodeOpen && (
        <Pincode
          title={'Please enter your PIN code'}
          closePinCode={() => setPincodeOpen(false)}
          handleEnterCompleted={async pincode => {
            try {
              const { data } = await axios.post(
                `${process.env.REACT_APP_API_URL}/withdrawal/confirm-pincode`,
                { pincode },
                { withCredentials: true }
              );

              if (data.ok) {
                setPincodeValid(true);
              } else {
                toast('PIN code is incorrect', { theme: 'dark', type: 'error' });
              }
            } catch (error) {
              console.log(error);
            } finally {
              setPincodeOpen(false);
            }
          }}
        />
      )}
      {withdrawalLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black/80 z-[99] text-2xl text-white font-bold">
          {successWithdrawalRequest ? (
            <div className="flex justify-center w-full px-[20px]">
              <div className="flex flex-col items-center space-y-5 max-w-[1000px]">
                <div className="text-center">{lngT('withdrawal:complete')} !🎉</div>
                <button
                  className="w-fit bg-[#860099] px-5 py-2 rounded-lg"
                  onClick={() => {
                    setWithdrawalLoading(false);
                    setSuccessWithdrawalRequest(false);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          ) : (
            'loading'
          )}
        </div>
      )}
      <InputContainer className="flex flex-col">
        <Label>{lngT('withdrawal:title:1')}</Label>
        <div className="flex flex-row space-x-6">
          <CoinTypeBtn $isSelected={paymentType === 'pix'} onClick={() => setPaymentType('pix')}>
            PIX Pay
          </CoinTypeBtn>
          <CoinTypeBtn
            $isSelected={paymentType === 'coin'}
            disabled
            className="opacity-40"
            onClick={() => setPaymentType('coin')}
          >
            COIN
          </CoinTypeBtn>
        </div>
      </InputContainer>
      <div className="flex flex-col space-y-5">
        <Line />
        <InputContainer className="flex flex-col">
          <Label>{lngT('withdrawal:title:2')}</Label>
          <div className="text-[13px] text-white">
            {lngT('withdrawal:withdrawableAmount')}:{' '}
            <span className="text-teal-400">
              {floorSecondDecimal(balance.data.coin - withdrawalPoint).toLocaleString()}
            </span>
            Point
          </div>
          <div className="relative">
            <Input
              type="text"
              pattern="[0-9]+"
              value={withdrawalPoint.toLocaleString()}
              onChange={handleChangeWithdrawalAmount}
            />
            <div className="absolute top-1/2 transform -translate-y-1/2 right-5 z-50 text-white text-[12px]">
              {/*POINT*/ lngT('points')} (100 P = 1 USD)
            </div>
          </div>
          <div className="flex flex-row space-x-4">
            <CoinPriceBtn onClick={handleClickAddChargePoint(100)}>100</CoinPriceBtn>
            <CoinPriceBtn onClick={handleClickAddChargePoint(1000)}>1,000</CoinPriceBtn>
            <CoinPriceBtn onClick={handleClickAddChargePoint(10000)}>10,000</CoinPriceBtn>
            <CoinPriceBtn onClick={handleClickAddChargePoint(balance.data.coin > 1 ? balance.data.coin : 0)}>
              Max
            </CoinPriceBtn>
            <CoinPriceBtn
              onClick={() => {
                setWithdrawalPoint(0);
                setRequestAmount(0);
                setFeeAmount(0);
                setTotalAmount(0);
              }}
            >
              Reset
            </CoinPriceBtn>
          </div>
          <div className="flex flex-row space-x-2">
            <InfoTitle>Info</InfoTitle>
            <div>
              <InfoText>{lngT('withdrawal:guideText:1')}</InfoText>
              <InfoText>{lngT('withdrawal:guideText:2')}</InfoText>
              {/* <InfoText>{lngT('withdrawal:guideText:3')}</InfoText> */}
            </div>
          </div>
        </InputContainer>
      </div>
      <Line />
      <InputContainer className="flex flex-col">
        <Label>{lngT('withdrawal:title:3')}</Label>

        <div className="relative">
          <Input type="text" disabled value={requestAmount} className="text-teal-300" />
          <div className="absolute top-1/2 transform -translate-y-1/2 left-2 z-50 text-teal-300">+</div>
          <div className="absolute top-1/2 transform -translate-y-1/2 right-5 z-50 text-white text-[12px]">
            {lngT('withdrawal:amountInfo:1')} (BRL)
          </div>
        </div>

        <div className="relative">
          <div className="absolute top-1/2 transform -translate-y-1/2 left-2 z-50 text-red-400">-</div>
          <Input type="text" disabled value={feeAmount} className="text-red-400" />
          <div className="absolute top-1/2 transform -translate-y-1/2 right-5 z-50 text-white text-[12px]">
            {lngT('withdrawal:amountInfo:2')} ({withdrawalFee && Number(withdrawalFee.fee) * 100}%) (BRL)
          </div>
        </div>

        <div className="w-full border border-white/20" />

        <div className="relative">
          <Input type="text" disabled value={totalAmount} className="text-white" />
          <div className="absolute top-1/2 transform -translate-y-1/2 left-2 z-50 text-white">=</div>
          <div className="absolute top-1/2 transform -translate-y-1/2 right-5 z-50 text-white text-[12px]">
            {lngT('withdrawal:amountInfo:3')} (BRL)
          </div>
        </div>

        <div className="flex flex-row space-x-2">
          <InfoTitle>Info</InfoTitle>
          <InfoText>{lngT('withdrawal:guideText:4')}</InfoText>
        </div>
      </InputContainer>
      <div className="hidden T:flex flex-row T:justify-center items-center text-[24px] font-semibold text-white px-[6px] T:pb-[24px]">
        <AccountWriteBtn className="T:w-[170px] text-[14px] px-[20px] rounded-xl" onClick={handleClickWithdrawal}>
          {lngT('withdrawal')}
        </AccountWriteBtn>
      </div>
      <AccountWriteBtn className="fixed left-0 right-0 bottom-0 T:hidden z-[99]" onClick={handleClickWithdrawal}>
        {lngT('withdrawal')}
      </AccountWriteBtn>
      <Line />
    </div>
  );
}
