import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { PIXEL_ACCESS_TOKEN } from '../../config';
import { useLocation } from 'react-router-dom';

export default function Pixel() {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.init(PIXEL_ACCESS_TOKEN);
  }, []);

  useEffect(() => {
    ReactPixel.pageView();
  }, [location]);

  return <div className="absolute w-[1px] h-[1px] bg-transparent" />;
}
