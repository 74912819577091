import React, { useEffect, useRef, useState } from 'react';
import { HiSpeakerXMark, HiSpeakerWave } from 'react-icons/hi2';

import useLang from '../../lib/hook/useLang';

type Props = {
  closeLandingVideo: () => void;
};

export default function LandingVideo({ closeLandingVideo }: Props) {
  const videoRef = useRef(null);
  const [muted, setMuted] = useState(true);
  const { currentLang } = useLang();
  const [isButtonOn, setButtonOn] = useState(false);
  const timer = useRef<NodeJS.Timeout>();
  const [videoSrc, setVideoSrc] = useState(window.innerWidth > 700 ? '/landing-desktop.mp4' : '/landing-mobile.mp4');

  const handleResize = () => {
    const width = window.innerWidth;
    const src =
      width > 700
        ? 'https://metacross-brazil-s3-bucket.s3.sa-east-1.amazonaws.com/video/landing-desktop.mp4'
        : 'https://metacross-brazil-s3-bucket.s3.sa-east-1.amazonaws.com/video/landing-mobile.mp4';
    setVideoSrc(src);
  };

  const handleVideoEnd = () => {
    closeLandingVideo();
  };

  const handleVideoStart = () => {
    timer.current = setTimeout(() => setButtonOn(true), 3000);
  };

  useEffect(() => {
    const src = window.innerWidth > 700 ? '/landing-desktop.mp4' : '/landing-mobile.mp4';
    setVideoSrc(src);
    window.localStorage.setItem('landing-video', JSON.stringify({ expire: Date.now() + 1000 * 60 * 60 * 24 }));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <div className="relative w-screen h-screen z-[9999]">
      {isButtonOn && (
        <div className="absolute bottom-[100px] right-[5%] z-[100]">
          <img
            src={`landing_button_${currentLang === 'pt' ? 'pt' : 'en'}.png`}
            alt=""
            onClick={handleVideoEnd}
            className="cursor-pointer"
          />
        </div>
      )}
      <div
        className={`absolute top-5 right-5 z-[100] flex justify-center items-center w-[50px] h-[50px] bg-white/30 rounded-full ${
          muted ? 'animate-pulse' : ''
        }`}
      >
        <button onClick={() => setMuted(!muted)} className="text-white text-2xl">
          {muted ? <HiSpeakerXMark /> : <HiSpeakerWave />}
        </button>
      </div>

      <img className="fixed top-0 right-0 bottom-0 left-0 z-[99] w-screen h-screen" src="/pattern.png" alt="" />
      <video
        ref={videoRef}
        className="fixed top-0 right-0 bottom-0 left-0 w-screen h-screen object-cover"
        playsInline
        autoPlay
        muted={muted}
        preload="auto"
        onEnded={handleVideoEnd}
        onPlay={handleVideoStart}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  );
}
